<template>
  <div>
    <div class="borderColor borderColorBackground mb-3">
      <div
        class="d-flex align-items-center justify-content-center flex-wrap mb-3"
      >
        <div class="mr-3 mt-3 d-flex align-items-center">
          選擇 INV :&nbsp;
          <b-form-select
            v-model="tableQueryParams.tableQuerySelectNum"
            :options="selectOptions"
            class="invSelection"
          />
        </div>
        <div class="mr-3 mt-3">
          選擇日期 :&nbsp;
          <date-picker
            v-model="tableQueryParams.tableQueryDate"
            placeholder="請選擇日期"
            format="YYYY-MM-DD"
            type="date"
            style="width: 150px"
            :disabled-date="disabledDateAfterToday"
          />
        </div>
        <button class="confirmButton mt-3" @click="confirmSearch">
          <b-spinner v-if="tableLoading" small class="m-1 mx-2" />
          <span>{{ $t("deviceHistory.confirm") }}</span>
        </button>
      </div>
    </div>

    <div class="borderColor">
      <div class="text-center d-flex flex-column titleBackground">
        <div class="highcharts-title mt-4">
          {{ $t("deviceHistory.specifiedINVInfoPerMin") }}: {{ tableTitle }}
        </div>

        <div class="d-flex align-items-center">
          顯示筆數:
          <b-form-select
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="ml-2 mr-3"
            style="width: 80px"
          />
          <button
            class="confirmButton ml-auto"
            style="width: 120px"
            @click="downloadAnalysis"
          >
            {{ $t("dataAnalysis.download") }}
          </button>
        </div>
      </div>

      <b-alert
        :show="!tableLoading && !hasTableData"
        variant="secondary"
        class="text-center font-weight-bold my-1"
      >
        目前暫無資料或該設備異常，請重新選擇日期或設備
      </b-alert>

      <div v-if="tableLoading" class="text-center m-5">
        <b-spinner class="align-middle mr-3" variant="secondary" />
        <strong class="text-secondary">讀取中...</strong>
      </div>

      <b-table
        v-if="!tableLoading && hasTableData"
        :items="tableData"
        :fields="tableField"
        hover
        class="tableStyle bTableSticky"
        :head-variant="tableThColor"
        :dark="dark"
        :responsive="true"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        sort-icon-left
        :per-page="perPage"
        :current-page="currentPage"
        :tbody-transition-props="transProps"
        primary-key="時間"
        sticky-header
      />
    </div>
    <b-pagination
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="rows"
      class="mt-2 mb-4"
    />
  </div>
</template>

<script>
import {
  BFormSelect,
  BTable,
  BPagination,
  BSpinner,
  BAlert
} from 'bootstrap-vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import downloadCsv from 'download-csv'
import i18n from '../lang/lang'
import { disabledDateAfterToday } from '@/utils/datetime'
export default {
  name: 'DeviceHistory',
  components: {
    BFormSelect,
    BTable,
    BPagination,
    BSpinner,
    BAlert
  },
  data () {
    return {
      sortBy: '時間',
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      tableQueryParams: {
        tableQueryDate: new Date(),
        tableQuerySelectNum: 1
      },
      tableLoading: false,
      sortDesc: true,
      tableTitle: ''
    }
  },
  computed: {
    ...mapState('deviceHistory', ['tableField', 'selectOptions', 'tableData']),
    ...mapGetters('deviceHistory', ['hasTableData']),
    ...mapState(['dark', 'tableThColor']),
    rows () {
      return this.tableData.length
    }
  },
  methods: {
    ...mapActions('deviceHistory', [
      'handleTableField',
      'setInvSelectOptions',
      'fetchTableData'
    ]),
    disabledDateAfterToday,
    confirmSearch () {
      if (!this.tableQueryParams.tableQueryDate) {
        return this.$swal('請選擇日期')
      }
      this.tableLoading = true
      this.tableTitle =
        'INV' + ('0' + this.tableQueryParams.tableQuerySelectNum).slice(-2)
      this.fetchTableData(this.tableQueryParams).then(() => {
        this.tableLoading = false
      })
    },
    downloadAnalysis () {
      // remove _cellVariants
      const downloadData = this.tableData.map((obj) => {
        const { _cellVariants, ...newObject } = obj
        return newObject
      })
      downloadCsv(downloadData, {}, '裝置資訊表.csv')
    },
    tableLang () {
      const deviceHistoryTableTitle = [
        'Time',
        'frequency',
        'abVoltage',
        'bcVoltage',
        'caVoltage',
        'APhaseCurrent',
        'BPhaseCurrent',
        'CPhaseCurrent'
      ]
      for (let i = 0; i < deviceHistoryTableTitle.length; i++) {
        if (this.tableField[i]) {
          this.tableField[i].label = i18n.t(
            `deviceHistory.tableTitle.${deviceHistoryTableTitle[i]}`
          )
        }
      }
      for (
        let i = deviceHistoryTableTitle.length;
        i < this.tableField.length;
        i++
      ) {
        if (this.tableField[i]) {
          let str = this.tableField[i].key.replace('MTTP', 'PV')
          let changelangstr = str.replace('電壓', ' ').replace('電流', ' ')
          this.tableField[i].label = i18n.locale === 'en' ? changelangstr : str
        }
      }
    }
  },

  created () {
    this.confirmSearch()
    this.handleTableField()
    this.setInvSelectOptions()
    this.tableLang()
  }
}
</script>
