// set vue datepicker disabled-date
const disabledDateAfterToday = (date) => {
  const today = new Date()
  today.setDate(today.getDate())
  today.setHours(0, 0, 0, 0)
  return date > new Date(today.getTime())
}

const disabledDateAfterYesterday = (date) => {
  const today = new Date()
  today.setDate(today.getDate() - 1)
  today.setHours(0, 0, 0, 0)
  return date > new Date(today.getTime())
}

export { disabledDateAfterToday, disabledDateAfterYesterday }
